<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="material-icons close_modal_icon" @click="$emit('close-modal')">close</span>
      </header>
      <section class="modal-card-body p-6">
        <div class="columns is-multiline is-centered">
          <div class="column is-8">
            <div class="field control">
              <label class="label">Sub línea de producto</label>
              <input
                class="input"
                type="text"
                placeholder="Sub línea de producto"
                v-model="subProductLine"
                name="subProductLine"
                v-validate="'required'"
                data-vv-as="de la sub línea de producto"
              >
              <span v-show="vverrors.has('subProductLine')" class="help is-danger">{{ vverrors.first('subProductLine') }}</span>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button button_micro_dark_outlined"
          @click="$emit('close-modal')"
        >
          CANCELAR
        </button>
        <button
          class="button button_micro_dark"
          @click="actionModal()"
        >
          AGREGAR
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSubLineChild',
  data () {
    return {
      subProductLine: null
    }
  },
  props: {
    idSelect: {
      type: Number,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async actionModal () {
      if (await this.$validator.validateAll()) {
        const data = {
          sublineId: this.idSelect,
          subProductLine: this.subProductLine
        }
        this.$emit('set-data-add-sub-line', data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: 18px;
  }
  .modal-card-head {
    border-bottom: none;
    justify-content: flex-end;
  }
  .modal-card-head, .modal-card-foot {
    background-color: white;
  }
  .modal-card-foot {
    border-top: none;
    justify-content: center;
    padding-bottom: 5rem;
  }

  .button {
    font-size: 14px;
    width: 30%;
  }
</style>
